import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import styles from "../css/error.module.css"
import Banner from "../components/banner"
import SEO from "../components/seo"

export default function Error({ location }) {
  return (
    <Layout location={location.pathname}>
      <SEO title="404" />
      <header className={styles.error}>
        <Banner title="Diese Seite konnte nicht gefunden werden.">
          <AniLink fade to="/" className="btn-white">
            Back Home
          </AniLink>
        </Banner>
      </header>
    </Layout>
  )
}
